.dashboard-scroller {
  height: 100%;
}
.dashboard-scroller .react-grid-item.expanded {
  position: fixed !important;
  top: 68px !important;
  left: 0 !important;
  width: inherit !important;
  z-index: 2 !important;
  transform: unset !important;
  height: calc(100vh - 68px) !important;
  transition: unset !important;
  box-shadow: unset !important;
}
.dashboard-scroller.printing {
  height: auto;
  overflow: visible;
}
.dashboard-scroller .boxed {
  box-shadow: 0 0px 4px 4px rgba(0, 0, 0, 0.1);
}
button.pulse {
  animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
.no-scroll {
  overflow: hidden !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
